import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Chat from "../components/chat"

const ChatPage = () => (
  <Layout>
    <Header>Chat test</Header>
    <Chat />
  </Layout>
)

export default ChatPage
